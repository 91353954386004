@import "./assets/styles/fonts";
@import './assets/styles/variables';

* {
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
    width: 100svw;
    min-height: 100svh;
    background-color: $colourBackground;
    color: white;
    display: flex;
    flex-direction: column;
    font-family: $fontSubHeading;

    @media all and (max-width: 900px) {
        height: auto;
        overflow-y: visible;
    }
}

header {
    width: 100%;
    text-align: center;
    padding: 30px;
    height: $headerHeightDesktop;
    display: flex;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 767px) {
        height: $headerHeightMobile;
    }

    h1 {
        font-family: $fontHeading;
        font-size: 5.5vw;
        margin: 0;
        letter-spacing: -2px;

        @media all and (min-width: 1600px) {
            font-size: 6rem;
        }

        @media all and (max-width: 767px) {
            font-size: 7vw;
        }
    }

    h2 {
        font-family: $fontSubHeading;
        font-size: 2.5vw;
        font-weight: 400;
        letter-spacing: 5px;
        margin: 0;

        @media all and (max-width: 600px) {
            font-size: 4vw;
        }
    }

}

footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    height: $footerHeightDesktop;


    .archive-wrap {
        text-align: left;
    }

    .socials-wrap {
        text-align: right;
    }

    a {
        color: $colourCopyMain;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 20px;

        &:last-child {
            margin: 0;
        }
    }
}

.content {
    flex: 1;
    display: flex;
    align-items: center; 
    height: calc(100svh - ($headerHeightDesktop + $footerHeightDesktop));
    min-height: 600px;
    position: relative;

    @media all and (max-width: 900px) {
        min-height: 950px;
    }

    @media all and (max-width: 767px) {
        height: calc(100svh - ($headerHeightMobile + $footerHeightDesktop));
        min-height: 900px;
    }

    @media all and (max-width: 767px) and (max-height: 610px) {
        //min-height: 460px;
    }

    @media all and (max-width: 600px) {
        height: calc(100svh - ($headerHeightMobile + $footerHeightDesktop));
        min-height: 800px;
    }

    @media all and (max-width: 500px) {
        min-height: 700px;
    }

    @media all and (max-width: 425px) {
        min-height: calc(100svh - ($headerHeightMobile + $footerHeightDesktop));
    }

    .content-wrap {
        position: relative;
        height: 100%;
        min-height: 600px;
        width: 100%;
        display: flex;
        flex-direction: column;

        @media all and (max-width: 900px) {
            min-height: 950px;
        }

        @media all and (max-width: 767px) {
            height: calc(100svh - ($headerHeightMobile + $footerHeightDesktop));
            min-height: 900px;
        }
    
        @media all and (max-width: 767px) and (max-height: 610px) {
            //min-height: 460px;
        }

        @media all and (max-width: 600px) {
            height: calc(100svh - ($headerHeightMobile + $footerHeightDesktop));
            min-height: 800px;
        }

        @media all and (max-width: 500px) {
            min-height: 700px;
        }

        @media all and (max-width: 430px) {
            height: 620px;
            min-height: 620px;
        }

    }
}

.telly {
    width: 420px;
    height: 320px;
    background: $colourTVBackground;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 230px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 900px) {
        bottom: 180px;
        bottom: calc(180px + $footerHeightDesktop);
    }

    @media all and (max-width: 768px) and (max-height: 1024px) {
        bottom: 230px;
    }

    @media all and (max-width: 600px) {
        width: 70vw;
        height: 53vw;
        aspect-ratio: 21 / 16;
        bottom: 170px;
    }

    .telly-text {
        position: relative;
        font-size: 1.5rem;
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .telly-image {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;

            img {
                width: 100%;

                &.hide {
                    display: none;
                }

            }

            
        }

        .telly-title {
            position: relative;
            background: rgba(0, 0, 0, 0.8);
            padding: 20px;
            width: 100%;
            font-size: 1.2rem;

            @media all and (max-width: 600px) {
                font-size: 1rem;
            }

            h3 {
                font-size: 1.5rem;
                margin: 5px 0;

                @media all and (max-width: 600px) {
                    font-size: 1.2rem;
                }
            }

            .butt-check-it {
                display: none;
                margin: 0 auto;
                height: 50px;
                margin-top: 20px;
                background: $colourButtonBackground;
                border: none;
                font-family: $fontSubHeading;
                font-size: 1.2rem;
                border-radius: 5px;
                padding: 0 20px;
                color: $colourBackground;
                transition: all 0.2s;
                cursor: pointer;

                &:focus-visible, &:hover {
                    transform: scale(1.1) rotate(5deg);                   
                }
            }
        }

        &.active {
            //justify-content: flex-end;

            .telly-title {
                .butt-check-it {
                    display: block;
                }
            }
        }
    }

    &::before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
        background-size: 100% 2px, 3px 100%;
        pointer-events: none;
        z-index: 1;
    }

    &::after {
        content: "";
        position: absolute;
        width: 568px;
        height: 478px;
        background: url('./assets/images/tv.png') center bottom no-repeat;
        background-size: contain;
        left: -72px;
        top: -48px;
        z-index: 2;
        pointer-events: none;

        @media all and (max-width: 600px) {
            width: 95vw;
            height: 80vw;
            aspect-ratio: 568 / 478;
            left: -12vw;
            top: -8vw;
        }
    }
    
}

.console-cartridges-wrap {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;;

    @media all and (max-width: 900px) {
        display: block;
        min-height: 100%;    
    }

    @media all and (max-width: 900px) and (min-height: 1000px) {
        min-height: calc(100svh - $footerHeightDesktop - $headerHeightDesktop);
    }

    @media all and (max-width: 768px) and (max-height: 1024px) {
        min-height: 100%;
    }

    .cartridges-left {

        .cartridge {
            pointer-events: all;
            cursor: pointer;

            &:nth-child(1) {

                &::after, &::before {
                    transform: rotate(10deg);
                }

                &::before {
                    background: url('./assets/images/projects/cartridge-project-0.png') center center no-repeat;
                    background-size: contain;
                }
                
            }

            &:nth-child(2) {
                &::after, &::before  {
                    transform: rotate(-5deg);
                }

                &::before {
                    background: url('./assets/images/projects/cartridge-project-1.png') center center no-repeat;
                    background-size: contain;
                }
            }

            &:nth-child(3) {
                &::after, &::before  {
                    transform: rotate(-15deg);
                }

                &::before {
                    background: url('./assets/images/projects/cartridge-project-2.png') center center no-repeat;
                    background-size: contain;
                }
            }
            
        }
    }

    .cartridges-right {

        .cartridge {
            pointer-events: all;
            cursor: pointer;

            &:nth-child(1) {
                &::after, &::before  {
                    transform: rotate(-23deg);
                }

                &::before {
                    background: url('./assets/images/projects/cartridge-project-3.png') center center no-repeat;
                    background-size: contain;
                }
            }

            &:nth-child(2) {
                &::after, &::before  {
                    transform: rotate(-10deg);
                }

                &::before {
                    background: url('./assets/images/projects/cartridge-project-4.png') center center no-repeat;
                    background-size: contain;
                }
            }

            &:nth-child(3) {
                &::after, &::before  {
                    transform: rotate(-20deg);
                }

                &::before {
                    background: url('./assets/images/projects/cartridge-project-5.png') center center no-repeat;
                    background-size: contain;
                }
            }
            
        }
    }

    .cartridges-left,
    .cartridges-right {
        flex: 0.25;
        height: 100%;
        display: flex;
        flex-direction: column;
        //justify-content: space-evenly;
        align-items: center;

        @media all and (max-width: 900px) {
            height: auto;
            flex-direction: row;
            //width: fit-content;
            //margin: 0 auto;
        }

        .cartridge {
            width: 180px;
            height: 124px;
            background: none;
            border: none;
            transform-origin: center;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            display: flex;
            margin: 30px 0;

            @media all and (max-width: 900px) {
                width: 22vw;
                height: 15vw;
                aspect-ratio: 45 / 31;
                margin: 20px 5.67vw;
            }

            @media all and (max-width: 600px) {
                width: 25vw;
                height: 15vw;
                aspect-ratio: 45 / 31;
                margin: 15px 5.33vw;
            }

            &::before {
                content: '';
                width: 45%;
                height: 77%;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                z-index: 1;
                transition: all 0.2s;
            }
    
            &::after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                background: url('./assets/images/cartridge.png') center center no-repeat;
                background-size: contain;
                transition: all 0.2s;
            }
            
            &.ease {
                transition: all 0.5s;
            }
    
            &:hover, &:focus {
    
                &::after, &::before {
                    transform: rotate(0deg) scale(1.2);
                }
    
            }
    
            &.active {
                z-index: 3!important;

                &::after, &::before {
                    transform: rotate(0deg);
                }

                @media all and (max-width: 900px) {
                    //width: 180px;
                    //height: 124px;
                    &::after, &::before {
                        transform: rotate(0deg);// scale(1.2) translateY(0px);;
                    }
                }

                @media all and (max-width: 600px) {
                    &::after, &::before {
                        transform: rotate(0deg) translateY(12vw) scale(1.1);
                    }
                }

            }
    
            &.dragging {
                rotate: 0;

                /*@media all and (max-width: 900px) {
                    width: 180px;
                    height: 124px;
                }*/
                
            }

            &.hit {

                @media all and (max-width: 900px) {
                    //width: 180px;
                    //height: 124px;

                    &::after, &::before {
                        transform: rotate(0deg);
                    }
                }

            }
        
        }
    }

    .console {
        width: 540px;
        height: 162px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        pointer-events: none;
        transition: all 0.2s;
    
        &.console-front {
            background: url('./assets/images/console-front.png') center center no-repeat;
            background-size: contain;
            position: absolute;
            z-index: 5;
        }
    
        &.console-back {
            background: url('./assets/images/console-back.png') center center no-repeat;
            background-size: contain;
            position: absolute;
            z-index: 2;
        }

        &.hit {
            transform: scale(1.1);
        }

        @media all and (max-width: 600px) {
            width: 80vw;
            height: 30vw;
            aspect-ratio: 540 / 162;
        }
    }
}

.window-resizing {
    width: 100svw;
    height: 100svh;
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: $colourBackground;
    color: $colourCopyMain;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    padding: 30px;
    text-align: center;
    z-index: 99999999;

    &.resizing {
        display: flex;

        @media all and (max-width: 768px) {
            display: none;
        }
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
        background-size: 100% 2px, 3px 100%;
        pointer-events: none;
    }

}

.detail-popover {
    width: 100svw;
    height: 100svh;
    position: fixed;
    z-index: 99999;
    background: rgba(35, 20, 56, 0.7);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }

    .project-details {
        padding: 50px 0;
        background: $colourTVCopyBackground;
        width: 80%;
        max-height: calc(90svh - 50px);
        max-width: 1000px;
        font-size: 1.1rem;
        font-family: $fontCopy;
        overflow-x: hidden;
        overflow-y: scroll;

        @media all and (max-width: 600px) {
            font-size: 0.9rem;
        }

        .project-copy {
            padding: 0 50px;
        }

        a {
            color: $colourCopyStrong;
            
        }

        h3 {
            font-family: "Madimi One", sans-serif;
            font-size: 1.5rem;
            margin-top: 0;
        }

        p {
            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-top: 0;
            }

            strong {
                color: $colourCopyStrong;
            }
        }

        .slider {
            padding: 0 50px;
            
            .swiper {
                overflow: visible;
                margin-bottom: 20px;
            }

            .swiper-slide {
                text-align: center;

                img {
                    max-width: 100%;
                    max-height: 40vh;
                }

                video {
                    width: 100%;
                    max-width: 610px;
                    height: 40vh;

                    @media all and (max-width: 768px) {
                        height: auto;
                    }
                }

            }

        }

        @media all and (max-width: 600px) {
            padding: 30px 0;
            width: 90%;

            .slider, .project-copy {
                padding: 0 30px;
            }
        }
    }

    .butt-close-details {
        width: 80%;
        max-width: 1000px;
        height: 50px;
        background: $colourCopyStrong;
        border: none;
        font-family: $fontSubHeading;
        font-size: 1.2rem;
        padding: 0 20px;
        color: $colourBackground;
        transition: all 0.2s;
        cursor: pointer;

        &:focus-visible, &:hover {
            font-size: 1.3rem;    
            background: $colourTVCopyBackground;   
            color: $colourCopyStrong;    
        }

        @media all and (max-width: 600px) {
            width: 90%;
        }
    }

}

.swiper-button-next:after, .swiper-button-prev:after {
    color: $colourCopyStrong!important;
}

.swiper-button-prev:after {
    position: absolute;
    left: -30px;
}

.swiper-button-next:after {
    position: absolute;
    right: -30px;
}

.swiper-pagination-bullet-active {
    background: $colourCopyStrong!important;
}
