$headerHeightDesktop: 200px;
$footerHeightDesktop: 50px;

$headerHeightMobile: 100px;

$colourBackground: #231438;
$colourTVCopyBackground: #120A1E;
$colourTVBackground: #8A2BE2;
$colourCopyMain: #FFFFFF;
$colourCopyStrong: #FF9BA6; 
$colourButtonBackground: #D6A2C8;
